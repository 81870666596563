import { memo, useCallback, useMemo } from 'react';
import { Button, Discount, Price, cn } from '@divlab/divanui';
import { IconBasket, IconCheckBasket } from '@divlab/divanui/icons';

import * as ModalWindows from '@Stores/ModalWindows';
import useRequest from '@Hooks/useRequest';
import useRenderType from '@Hooks/useRenderType';
import useMedias from '@Hooks/useMedias';
import { useMeta } from '@Queries/Meta';
import { useHasInCart } from '@Queries/Cart';
import useNavigation from '@Navigation/useNavigation';
import useTranslation from '@Queries/useTranslation';
import ProductTags from '@Components/ProductTags';
import ProductLike from '@Components/ProductLike';
import Characteristics from '@Components/Characteristics';
import BuyButton from '@Components/BuyButton';
import Link from '@Navigation/Link';
import getProductName from '@Utils/getProductName';
import LinearTag from '@Promo/elems/LinearTag';
import ProductSelect from './elements/ProductSelect';
import SEOMetaData from './elements/SEOMetaData';
import ProductImage from '@Components/ProductImage';
import styles from './CrossSaleProductCard.module.css';

import type { ProductData, ProductGroups } from '@Types/Product';
import type { HTMLAttributes, FC, MouseEvent } from 'react';

export interface CrossSaleProductCardProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  product: ProductData;
  hiddenCharacteristics?: boolean;
  hiddenBuyLink?: boolean;
  isExtraSale?: boolean;
  imageLazyLoading?: boolean;
  onClickProductLink?: (e: MouseEvent) => void;
  needBuyButton?: boolean;
  analyticsLabel?: string;
}

const CrossSaleProductCard: FC<CrossSaleProductCardProps> = (props) => {
  const {
    className,
    product,
    hiddenCharacteristics,
    hiddenBuyLink,
    isExtraSale,
    imageLazyLoading,
    onClickProductLink,
    needBuyButton,
    itemScope,
    analyticsLabel,
    ...restProps
  } = props;
  const { isCSR, isSSR } = useRenderType();
  const { isMobileM } = useMedias();
  const { t } = useTranslation();
  const { country } = useRequest();
  const hasExpired = product.price.expired > 0;
  const hasDiscount = product.price.discount > 0;
  const [firstImage] = product.images;
  const orientation = product.orientation || 'landscape';
  const isUZB = country === 'UZB';
  const hasInCart = useHasInCart({ productId: product.id });
  const { openPage } = useNavigation();
  const meta = useMeta();
  const { region } = meta.data;

  const showSelect = useMemo(() => {
    if (!product.variants) return false;

    const { parameterGroupId } = product.variants;
    const place = product.parameterGroups.find((group) => group.id === parameterGroupId)?.place;

    return place === 'default';
  }, [product.variants, product.parameterGroups]);

  const separatedProductGroups = useMemo<ProductGroups>(() => {
    const groups: ProductGroups = { default: [], secondary: [] };

    product.parameterGroups.forEach((item) => {
      if (!groups[item.place]) groups[item.place] = [];

      if (item.id !== product.variants?.parameterGroupId) {
        groups[item.place].push(item);
      }
    });

    return groups;
  }, [product.parameterGroups, product.variants]);

  const handleBuy = useCallback(
    (e: MouseEvent) => {
      if (onClickProductLink) onClickProductLink(e);

      if (hasInCart) {
        openPage({ url: `${region.url}/order/check` });
        return;
      }

      ModalWindows.open('Cart', {
        products: [
          {
            isModular: false,
            shopProductId: product.id,
          },
        ],
        analyticsLabel,
      });
    },
    [onClickProductLink, hasInCart, product.id, analyticsLabel, openPage, region.url],
  );

  return (
    <div
      {...restProps}
      data-testid='product-card'
      itemScope={itemScope}
      className={cn(
        styles.card,
        {
          [styles.promo]: product.promo,
        },
        className,
      )}
    >
      <div className={styles.img}>
        <Link to={product.link} onClick={onClickProductLink}>
          <ProductImage
            className={cn(styles.image, {
              [styles.landscape]: orientation === 'landscape',
              [styles.portrait]: orientation === 'portrait',
            })}
            cnImage={styles.cnImage}
            src={firstImage?.src}
            alt={getProductName(product)}
            loading={imageLazyLoading ? 'lazy' : 'eager'}
            itemProp={itemScope && 'image'}
          />

          {product.extraDiscount && (
            <LinearTag
              type='extra'
              className={cn(styles.extraSaleTag, {
                [styles.bottomPlacement]: isExtraSale,
              })}
            />
          )}
        </Link>

        <div className={styles.actions}>
          <ProductLike product={product} label='Листинг' />
        </div>
        {product.tags?.length > 0 && (
          <ProductTags
            className={cn(styles.tags, {
              [styles.hidden]: isExtraSale,
            })}
            tags={product.tags}
          />
        )}
      </div>
      <div className={styles.info}>
        <Link
          className={cn(
            styles.name,
            'ProductName',
            product.status === 'Disable' && 'ForCount',
            product.status === 'Active' && 'ActiveProduct',
          )}
          to={product.link}
          onClick={onClickProductLink}
        >
          <span itemProp={itemScope && 'name'}>{getProductName(product)}</span>
        </Link>

        {itemScope && <SEOMetaData product={product} />}
        <div className={cn(styles.wrapperPrice, { [styles.longPrice]: needBuyButton && isUZB })}>
          <div className={styles.price}>
            {/* Требование SEO чтобы от сервера всегда приходила цена,
            и только на клиенте изменялась на Нет в наличии */}
            {isCSR && !product.isActive ? (
              <div>{t('ui.not-available')}</div>
            ) : (
              <>
                <Price className={styles.actualPrice} price={product.price.actual} />
                {hasExpired && (
                  <Price
                    expired
                    className={styles.expiredPrice}
                    price={product.price.expired}
                    withoutCurrency={isUZB && !needBuyButton && !isMobileM}
                  />
                )}
                {hasDiscount && (
                  <Discount size='s' className={styles.discount}>
                    {`-${product.price.discount}%`}
                  </Discount>
                )}
              </>
            )}
          </div>
          {isMobileM && needBuyButton && (
            <>
              {hasInCart ? (
                <Button
                  view='circle'
                  theme='transparent'
                  className={styles.buyMobile}
                  onClick={handleBuy}
                  data-testid='icon-buy-button-added'
                >
                  <IconCheckBasket className={styles.iconBasket} />
                </Button>
              ) : (
                <Button
                  view='circle'
                  className={styles.buyMobile}
                  onClick={handleBuy}
                  data-testid='icon-buy-button'
                >
                  <IconBasket className={styles.iconBasket} theme='light' />
                </Button>
              )}
            </>
          )}
        </div>
        {separatedProductGroups.default.length > 0 && (
          <Characteristics
            className={styles.parameters}
            groups={separatedProductGroups.default}
            product={product}
          />
        )}

        {showSelect && (
          <ProductSelect
            className={styles.sizes}
            product={product}
            onClickItem={onClickProductLink}
          />
        )}

        {/* По требованию SEO в некоторых местах сайта (например на странице категорий) необходимо получать в разметке информацию о характеристиках */}
        {hiddenCharacteristics && isSSR && (
          <>
            {separatedProductGroups.secondary?.length > 0 && (
              <Characteristics
                className={styles.hiddenCharacteristics}
                groups={separatedProductGroups.secondary}
                product={product}
              />
            )}
          </>
        )}

        {/* По требованию SEO в некоторых местах сайта (например на странице категорий) необходимо получать разметку кнопки купить */}
        {hiddenBuyLink && isSSR && <BuyButton href={product.link} />}
      </div>
    </div>
  );
};

export default memo(CrossSaleProductCard);
