import { memo } from 'react';

import * as Meta from '@Queries/Meta';
import useRequest from '@Hooks/useRequest';

import type { FC } from 'react';
import type { ProductData } from '@Types/Product';

export interface SEOMetaDataProps {
  product: ProductData;
}

const SEOMetaData: FC<SEOMetaDataProps> = (props) => {
  const { product } = props;
  const { origin } = useRequest();
  const meta = Meta.useMeta();

  return (
    <>
      <link itemProp='url' href={`${origin}${product.link}`} />
      <meta itemProp='price' content={product.price.actual.toString()} />
      <meta itemProp='priceCurrency' content={meta.data?.currency} />
      {product.isActive && <link itemProp='availability' href='http://schema.org/InStock' />}
    </>
  );
};

export default memo(SEOMetaData);
